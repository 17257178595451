*{
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;

    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: normal;
    color: var(--corFont);
}

:root{
    --corFont: black;

    --corFundo: #fcfcfc;
    /* #f0efee #FDFCF8 */
}

        /*  */


.theuz-header{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 60px;
    background-color: var(--corFundo);
    background-image: url(imgTheuz/fundo.png);

    
    position: fixed;
    z-index: 99;
    width: 100%;
    box-shadow: 3px 5px 15px 2px rgba(0, 0, 0, 0.103);
}

.theuz-header a {
    position: relative;
    overflow: hidden;
    border: none;
    text-decoration: none;
    color: var(--corFont);
    font-size: 18px;
}
   
.theuz-header a:after {
    content: " ";
    width: 0%;
    height: 5%;
    background: black;
    position: absolute;
    transition: all 0.4s ease-in-out;
    right: 0;
    bottom: 0;
}

.theuz-header a:hover::after {
    right: auto;
    left: 0;
    width: 100%;
    bottom: 0;
}

.theuz-header img{
    width: 100px;
    cursor: pointer;
    background-image: url(imgTheuz/theuz.png);
}

.theuz-header img:hover{
    transform: scale(0.97);
    transition: 0.3s;
}

        /*  */


.theuz-main{
    width: 100%;
    margin: auto;

    background-color: #f0efee;
    /* background-image: url(img/bo\ rd\ \(2\).png); */
}

        /*  */


.inicio{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /* margin: auto;    */

    /* background-image: url(img/bo\ rd\ \(3\).png); */

    /* background-color: beige; */
    box-shadow: 10px 5px 10px 10px rgba(0, 0, 0, 0.089);
}

.inicio h1, .theuz-sobre h1{ 
    font-family: "Bebas Neue", sans-serif;
    font-weight: 500;
    font-style: normal;

    text-align: center;
    letter-spacing: 4px;
    width: 90%;
}

.theuz-article{
    width: 90%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 60px auto;

}

/*  */
.produtos{
    display: flex;
    flex-direction: column;

    align-items: center;
    height: 600px;
    width: 100%;
}

.block {
    position: absolute;
    top: 1;
    left: 1;
    width: 100%;
    height: 600px;
    background-color: rgba(255, 255, 255, 0.548);
    backdrop-filter: blur(1px);
    z-index: 1;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: all;  /* permite que os eventos de clique passem através do elemento bloqueado */
}

.block::after {
    content: "Em Breve";
    font-size: 40px;
    color:tomato;
    font-family: "Bebas Neue", sans-serif;
    font-weight: 500;
    font-style: normal;
}

.colorido{
    background-color: var(--corFundo);
    box-shadow: 3px 5px 15px 2px rgba(0, 0, 0, 0.158);
}

/*  */

.info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;

    margin-top: 100px;
}

.reverse{
    max-width: 50%;
}

.titulo{
    width: 600px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lista{
    width: 600px;
}

.lista ul{
    list-style-type: disc;
}

.lista ul li{
    margin: 10px;
}

.produtos h2, .produtos li{
    animation: appear 1ms ease-in-out forwards;
    animation-timeline: view(); /* esse view faz com que ao chegar em um determinado height da tela a animação ocorra */
    animation-range: cover 0% cover 35%;
}

@keyframes show {
    from{
        opacity: 0;
        transform: scale(0);
    }
    to{
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes appear {
        from{
            opacity: 0;
            translate: -100vw 0;
        }to{
            opacity: 1;
            translate: 0 0;
        }
}

.imagem-junta{
    animation: show 2s;
    animation-timeline: view(); /* esse view faz com que ao chegar em um determinado height da tela a animação ocorra */
    animation-range: cover 0% cover 30%;

    width: 90%;
    display: flex;
    justify-content: center;
    
    gap: 10px;
    margin: 80px 0;
}


.imagem-junta img{
    margin: 0;
    padding: 0;
    width: 200px;
    height: 200px;
    cursor: pointer;
    object-fit: cover;
    object-position: top;
    border: 3px solid #dddd;
}

.imagem-teste{
    width: 200px;
    height: 200px;
}

.imagem-teste:hover{
    transform: scale(0.97);
    transition: 0.80s;
}

.theuz-article .modal {
    width: 100vw;
    height: 100vh;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    
    position: fixed;
    top: 1;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9999;
    overflow-y: auto;
    cursor: pointer;
    margin: 0;
    padding: 0;
}

.theuz-article .modal-image {
    max-width: 98%;
    max-height: 500%;
    object-fit: contain;
    display: block;
    margin: auto;
    padding: 10px 0;
}

/*  */
.contato{
    width: 90%;
    height: 600px;
    margin: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;

    background-color: var(--corFundo);
}

.contato h2{
    text-align: center;
}

.contato strong{
    font-weight: bolder;
}

.formulario-theuz{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 600px;
    margin: 0 auto;
}

.formulario-theuz form{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 90%;
}

.formulario-theuz input[type="text"]{
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

.formulario-theuz input[type="email"], #service{
    width: 49%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

.formulario-theuz input[name="obs"]{
    height: 180px;
    width: 100%;
}

.formulario-theuz button[type="submit"]{
    color: var(--corFundo);
    background-color: var(--corFont);
    font-size: 18px;
    padding: 10px;
    width: 50%;
    border: 1px solid #ccc;
    align-self: center;
    cursor: pointer;
}

.formulario-theuz button[type="submit"]:hover{
    transition: 0.8s;
    background-color: var(--corFundo);
    color: var(--corFont);
}

.theuz-footer{
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    color: white;
    background-color: rgb(0, 0, 0);
}

.theuz-footer p{
    color: white;
}


@media screen and (max-width: 780px) {

    .theuz-header{
        gap: 40px;
    }

    .theuz-header a{
        font-size: 14px;
    }

    .theuz-header a:hover::after {
        right: auto;
        left: 0;
        width: 0%;
        bottom: 0;
    }

    /*  */    

    .titulo, .lista{
        width: 90%;
    }

    .lista{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .lista ul li{
        width: 90%;
        margin: 5px auto;
    }

    .info{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 50px;
        width: 100%;
    }

    .reverse{
        flex-direction: column-reverse;
        max-width: none;
    }

    .produtos{
        height: fit-content;
    }

    .imagem-junta{
        margin: 20px auto 60px auto;
        width: fit-content;
        height: 100%;
        /* overflow: hidden; */
        gap: 15px;

        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .imagem-junta > img{
        width: 90px;
        height: 90px;
    }

    .imagem-teste:hover{
        transform: scale(1);
    }
    /*  */  

    .inicio img{
        /* width: 340px; */
        width: 300px;
    }

    .formulario-theuz button[type="submit"]:hover{
        transition: 0;
        background-color: white;
        background: none;
    }

}

@media screen and (min-width: 780px) and (max-width: 1000px) {

    .imagem-junta > img{
        width: 150px;
        height: 150px;
    }

    .formulario-theuz button[type="submit"]:hover{
        transition: 0;
        background-color: white;
        background: none;
    }
}

/*  */

.alert-theuz {
    z-index: 1;
    position: absolute;
    top: 1;
    left: 1;
    right: 1;
    bottom: 1;
    padding: 40px;
    text-align: center;
    font-size: 20px;
}

.alert-theuz-success {
    color: #155724;
    background-color: #d4edda;
}

.alert-theuz-danger {
    color: var(--corFont);
    background-color: rgba(255, 99, 71, 0.842);
}

/* teste */

.theuz-sobre{
    width: 90%;
    max-width: 1100px;
    margin: 100px auto;
}

.theuz-sobre h1{
    text-align: right;
    margin: 40px 40px 40px 0;
    font-size: 40px;
}

.theuz-sobre h3{
    font-size: 25px;
    margin: 20px 0;
}

.theuz-sobre ul{
    list-style: none;
}

.theuz-sobre li{
    margin: 5px 0;
}

.theuz-sobre li::first-letter, .theuz-sobre p::first-letter{
    margin-left: 20px;
}

.theuz-sobre strong{
    font-weight: bolder;
    font-size: 18px;
}

.theuz-sobre p:last-child{
    margin: 60px auto;
    max-width: 600px;
    text-align: center;
}