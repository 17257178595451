*{
    margin: 0;
    padding: 0;
}

.banner{
    width: 100%;
    height: 100vh;
    background-color: white;
}

.banner > img{
    object-fit: cover;
}