@charset "UTF-8";

body {
    
    background-color: #f4f4f4;
}

.reserva *{
    font-family: 'Arial', sans-serif;
}

.reserva{
    width: 100%;
    margin: auto;
    padding-top: 20px;
}

.header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
}

.header img{
    width: 100px;
    height: 100px;
}

.formulario{
    width: 80%;
    /* margin: auto; */
    padding: 35px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    max-width: 800px; /* Define a largura máxima */
    margin: 0 auto;

    gap: 5px;

    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.555);
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.356);
}

.opcoes{
    display: flex;
    flex-direction: column;
}

.reserva > .formulario > div:nth-child(n+5) {
    grid-column: span 1; 
}

.reserva > .formulario > div:nth-child(n+7) {
    grid-column: span 2; 
}

label {
    margin-bottom: 8px;
    color: #555;
}

input,
select {
    width: 85%;
    padding: 10px;
    margin-bottom: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
}


button {
    color: #fff;
    padding: 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.alert {
    z-index: 9;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 40px;
    margin: 20px auto;
    width: 50%;
    border: 1px solid #ddd;
    border-radius: 10px;
    color: #333;
    background-color: #f8d7da;
    text-align: center;
}

.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

/* DATA */

label[for='date']{
    margin-top: 30px;
}

.date-container{
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.date-container button{
    background-color: #333;
    color: #fff;
    width: 140px;
    height: 80px;
    margin: 1%;
    font-size: 13px;
    text-align: center;
}

.date-container > button::first-letter{
    text-transform: capitalize;
}

.date-container > button.selected {
    background-color: #45a049;
    color: #fff;
}


/* HORA */

label[for=time]{
    margin-bottom: 30px;
}

.time-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 50px;
}

.time-container button{
    background-color: #333;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    padding: 15px 20px;
}

.unavailable-time{
    background-color: tomato;
}

.opcoes button:hover{
    background-color: #45a049;
}

#submit-button{
    background-color: #4caf50;
    transform: none;
}

.time-container > button.selected {
    background-color: #45a049;
    color: #fff;
}


/* Avisos */
.modal {
    display: none;
    position: fixed;
    z-index: 9;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    padding-top: 60px;
}

.modal-content {
    position: relative;
    background-color: #fefefe;
    margin: 5% auto;
    padding: 20px;
    border: 1px solid #888;
    max-width: 350px;
    display: flex;
    flex-direction: column;
}

.modal-content > button{
    margin: 40px auto 5px auto;
    padding: 15px 30px;
    width: fit-content;
    font-size: 18px;
    background-color: #45a049;
}

.modal-message {
    text-align: center;
}

.modal-message h2 {
    margin-bottom: 60px;
    font-size: 26px;
}

.modal-message p {
    display: block;
    margin: 10px 0;
    font-size: 18px;
}

.modal-message p strong {
    margin-right: 5px;
}
/*  */
button.occupied {
    display: none;
}

/*  */
.btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Montserrat", sans-serif;
    box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    border: none;
    z-index: 1;
   }
   
   .btn:after {
    content: " ";
    width: 100%;
    height: 0%;
    background: #333;
    position: absolute;
    transition: all 0.4s ease-in-out;
    bottom: 0;
    z-index: 1;
   }
   
   .btn:hover::after {
    /* right: auto;
    left: 0; */
    bottom: auto;
    top: 0;
    height: 100%;
    z-index: 1;
   }
   
   .btn span {
    text-align: center;
    text-decoration: none;
    width: 100%;
    padding: 18px 25px;
    color: #fff;
    font-size: 1.125em;
    font-weight: 700;
    letter-spacing: 0.3em;
    z-index: 1;
    transition: all 0.3s ease-in-out;
   }
   
   .btn:hover span {
    color: #fff;
    animation: scaleUp 0.3s ease-in-out;
   }
   
   @keyframes scaleUp {
    0% {
     transform: scale(1);
    }
   
    50% {
     transform: scale(0.92);
    }
   
    100% {
     transform: scale(1);
    }
   }
/*  */
.loading, .delete-loading{
    z-index: 9;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0%;
    left: 0%;
    background-color: rgba(255, 255, 255, 0.432);
    
    display: none; /* Ocultar o loader inicialmente */
}

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 3px solid;
    width: 100px;
    height: 100px;
    color: black;
}

.loader::after {
    position: absolute;
    width: 0px;
    height: 40px;
    display: block;
    border-left: 3px solid black;
    content: '';
    left: 50%;
    border-radius: 1px;
    top: 10%;
    animation-duration: 1s;
}

.loader::before {
    position: absolute;
    width: 0px;
    height: 40px;
    display: block;
    border-left: 3px solid black;
    content: '';
    left: 50%;
    border-radius: 1px;
    top: 10%;
    animation-duration: 40s;
}

.loader::before, .loader::after {
    transform-origin: bottom;
    animation-name: dial;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes dial {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* ---- */

.abas{
    width: 80%;
    max-width: 800px;
    display: flex;
    justify-content: space-between;
    margin: auto;
}

.abas > button{
    width: 100px;
    background-color: #333;
    color: white;

    transition: opacity 0.3s, transform 0.3s;
}

#showAppointmentFormBtn{
    background-color: #45a049;
}

#showDeleteFormBtn{
    background-color: tomato;
}

.button-clicked {
    opacity: 0.5;
    transform: scale(0.94);
}

/* ---- */

.delete-form{
    width: 80%;
    /* margin: auto; */
    padding: 35px;
    display: none;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    max-width: 800px; /* Define a largura máxima */
    margin: 0 auto;

    gap: 5px;

    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.555);
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.356);
}


.delete-form div:nth-child(n+3) {
    grid-column: span 2; /* Ocupar duas colunas */
}

.delete-appointment{
    flex-direction: column;
}

.delete-btn{
    background-color: tomato;
    padding: 30px 0;
}

.opcoes .delete-btn:hover{
    background-color: black;
}